<template>
  <div>
    <!-- for moblie start -->
    <div class="hidden md:block space-y-4">
      <div
        v-for="(row, index) in rows"
        :key="row.id"
        class="bg-white shadow rounded"
      >
        <div class="flex justify-between border-b p-4">
          <div class="">{{ index + 1 }}. {{ row.sbjName }}</div>
          <div class="text-sm text-gray">{{ row.date }}</div>
        </div>
        <div class="p-4 flex justify-between items-center">
          <div class="">作答時間 {{ secondsToTime(row.ansTime) }}</div>
          <BtnResult :test-id="row.testId" :size="'small'" />
        </div>
      </div>
    </div>
    <!-- for moblie END -->
    <!-- for pc start -->
    <el-table
      class="md:hidden"
      :data="rows"
      style="width: 100%"
      header-cell-class-name="text-gray-light font-normal text-sm"
      cell-class-name="text-sm"
      @sort-change="onSorter"
    >
      <!-- 編號 -->
      <el-table-column prop="no" label="編號" width="60" />

      <!-- 試卷年分 -->
      <el-table-column
        prop="date"
        label="測驗時間"
        class-name="text-gray-light"
        width="140"
        sortable="custom"
      />

      <!-- 試卷名稱 -->
      <el-table-column
        prop="typeName"
        label="測驗來源"
        width="100"
        class-name="text-black"
        sortable="custom"
      />
      <el-table-column
        prop="sbjName"
        label="考試科目"
        class-name="text-black"
      />

      <el-table-column
        prop="emName"
        label="試卷來源"
        width="240"
        class-name="text-black"
        sortable="custom"
      >
        <template #default="scope">
          <el-tooltip
            effect="dark"
            :content="scope.row.emName"
            placement="top-start"
          >
            <p class="truncate">
              {{ scope.row.emName }}
            </p>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="題數" prop="qCnt" width="60" sortable="custom" />

      <!-- 專業科目 -->
      <el-table-column
        label="作答時間"
        prop="ansTime"
        width="120"
        align="right"
        sortable="custom"
      >
        <template #default="scope">
          {{ secondsToTime(scope.row.ansTime) }}
        </template>
      </el-table-column>

      <!-- 答題正確率 -->
      <el-table-column
        label="答題正確率"
        width="120"
        prop="avgCorrectRate"
        align="right"
        sortable="custom"
      >
        <template #default="scope">
          <Score class="text-right" :score="scope.row.avgCorrectRate"></Score>
        </template>
      </el-table-column>

      <!-- 收藏 -->
      <el-table-column label="" width="150">
        <template #default="scope">
          <BtnResult :test-id="scope.row.testId" :size="'small'" />
        </template>
      </el-table-column>
      <template #append>
        <BtnMore v-if="!isEnding" @more="more"></BtnMore>
      </template>
      <template #empty>
        <div class="text-center">{{ emptyText }}</div>
      </template>
    </el-table>
  </div>
</template>

<script>
import { inject, reactive } from 'vue';
import BtnResult from '@/modules/tester/BtnResult.vue';
import { getJoinString, secondsToTime } from '@/utils';
import Score from '@/components/Score.vue';
import BtnMore from '@/components/BtnMore.vue';
import { ElTable, ElTableColumn, ElTooltip } from 'element-plus';
export default {
  components: {
    ElTable,
    ElTooltip,
    ElTableColumn,
    Score,
    BtnResult,
    BtnMore,
  },
  props: {
    emptyText: {
      type: String,
      default: '目前沒有任何測驗的紀錄',
    },
    rows: {
      type: Array,
      default: null,
    },
  },
  setup(props) {
    const { more, updateForm, isEnding } = inject('exams');
    const form = reactive({
      orderItem: 'date',
      order: 1,
    });

    const onSorter = (e) => {
      form.order = e.order === 'ascending' ? 0 : 1;
      form.orderItem = e.prop;
      form.pg = 1;
      updateForm({ ...form });
    };

    return {
      getJoinString,
      onSorter,
      more,
      isEnding,
      secondsToTime,
    };
  },
};
</script>

<style lang="sass" scoped>

:deep(.el-table)
  .cell
    word-break: break-word
</style>
