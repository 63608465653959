import { reactive, inject, computed } from 'vue';
import _ from 'lodash';
import Question from '@/api/Question';
import Exam from '@/api/Exam';
export default ({ api }) => {
  const { guard } = inject('auth');
  const form = reactive([]);
  const API = api === 'question' ? Question : Exam;

  const update = ({ id, saved, type }) => {
    if (!guard()) return;

    API.save({ id: id, saved: saved ? 1 : 0, type }).then((res) => {
      if (res.code === 1) {
        form.splice(0, form.length, ..._.xor(form, [id]));
      }
    });
  };
  const set = (rows) => {
    // clear();

    rows.forEach((x) => {
      if (x.saved && form.indexOf(x.id) < 0) {
        form.push(x.id);
      }
    });
  };
  const clear = () => {
    form.length = 0;
  };
  const isSaved = (id) => {
    return form.indexOf(id) > -1;
  };
  return { form, update, set, clear, isSaved };
};
