export default (to, form, next) => {
  if (to.query?.pg && to.query.pg != 1) {
    next({
      ...to,
      query: {
        ...to.query,
        pg: 1,
      },
    });
  } else {
    next();
  }
};
