<template>
  <el-button type="primary" :disabled="disabled" :size="size" @click="to">
    <font-awesome-icon :icon="['fas', 'pen']" class="mr-1" />
    查看測驗結果
  </el-button>
</template>

<script>
import { toRefs } from 'vue';

import { useRouter } from 'vue-router';
export default {
  components: {},
  props: {
    testId: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const { testId } = toRefs(props);
    const to = async () => {
      router.push({
        name: 'Tester.Result',
        params: { testId: testId.value },
      });
    };

    return {
      to,
    };
  },
};
</script>
