<template>
  <div class="py-8 container">
    <div
      class="flex justify-between box items-center md:flex-col md:items-start"
    >
      <div class="flex items-center">
        <Titles :text="'測驗紀錄'" />
        <Count :total="rowsData.total"></Count>
      </div>
    </div>
    <div v-loading="loading" class="my-6">
      <List :rows="rowsData.rows" />
    </div>
  </div>
</template>

<script>
import { provide, ref, inject } from 'vue';
import Titles from '@/components/Titles.vue';
import { useRoute } from 'vue-router';
import _ from 'lodash';
import Count from './_Count.vue';
import List from '@/modules/exam/list/History.vue';
import useExam from '@/modules/exam/useExam';
import Tester from '@/api/Tester.js';
import usePgReset from '@/hook/usePgReset.js';
export default {
  components: {
    List,
    Titles,
    Count,
  },
  beforeRouteEnter(to, from, next) {
    usePgReset(to, from, next);
  },
  setup() {
    const isMobile = inject('isMobile');

    const route = useRoute();

    const exams = useExam({
      method: ref(Tester.history),
      defaultForm: {
        // type: 'past',
      },
    });
    provide('exams', exams);
    exams.fetcher();

    return {
      // onTypeUpdate,
      type: route.params.slug,
      form: exams.form,

      dataType: exams.dataType,
      rowsData: exams.rowsData,
      loading: exams.loading,

      isMobile,
    };
  },
};
</script>
