import { reactive, ref, onMounted, watch, shallowReactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import _ from 'lodash';
import { isEmpty } from '@/utils/index';

export default ({ method, defaultForm, cb }) => {
  const defaultSize = 12;
  const route = useRoute();
  const router = useRouter();
  const isMore = ref(false);
  const _defaultForm = {
    ...defaultForm,
    size: defaultSize,
    pg: 1,
  };

  const form = reactive({
    ..._defaultForm,
    ...route.query,
  });

  const rowsData = shallowReactive({
    total: 0,
    rows: shallowReactive([]),
  });

  const dataType = reactive([]);

  const loading = ref(false);

  const clear = () => {
    rowsData.rows.length = 0;
  };

  let top = 0;

  const isEnding = ref(false);
  const fetcher = async () => {
    loading.value = true;
    isEnding.value = false;
    if (!isMore.value) {
      clear();
    }

    const params = {
      ..._.omitBy({ ...route.query, ...form }, isEmpty),
    };

    if (params.redirectUri) {
      params.redirectUri = null;
    }
    if (params._r) {
      params._r = null;
    }

    const res = await method.value({
      ...params,
    });

    if (res.code === 1) {
      rowsData.rows.push(...res.data.rows);

      if (res.data.all) {
        rowsData.total = res.data.all;
      }

      if (top) {
        setTimeout(() => {
          window.scrollTo({
            top,
          });
        }, 0);
      }
    } else {
      console.log('No Data!');
    }

    if (
      !res.data?.rows ||
      res.data?.rows?.length < defaultSize ||
      rowsData.rows.length === res.data.all
    ) {
      isEnding.value = true;
    }

    if (cb && typeof cb === 'function') {
      cb(res);
    }

    dataType.length = 0;

    rowsData.total = res.data?.all ? res.data.all : 0;

    if (res.data?.dataType) {
      dataType.push(...res.data.dataType);

      if (!rowsData.total) {
        const all = res.data.dataType.find((x) => x.key === 'all');
        rowsData.total = all ? all.cnt : 0;
      }
    }

    loading.value = false;
    isMore.value = false;

    return res;
  };

  const reset = () => {
    for (const prop of Object.getOwnPropertyNames(form)) {
      delete form[prop];
    }
    Object.assign(form, { ..._defaultForm });
  };

  const toggle = (row) => {
    row.isAdded = !row.isAdded;
  };

  const add = (row) => {
    row.isAdded = !row.isAdded;
  };

  const more = () => {
    top =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    isMore.value = true;

    form.pg = Number(form.pg) + 1;
  };

  const syncQuery = () => {
    router.push({
      query: { ..._.omitBy({ ...form }, isEmpty) },
    });
  };
  onMounted(() => {
    // syncForm({ pg: 1 });
  });
  return {
    fetcher,
    clear,
    loading,
    form,
    rowsData,
    dataType,
    more,
    // like,
    // likeById,
    add,
    toggle,
    reset,
    // syncForm,
    syncQuery,
    isEnding,
    // saveds,
    isMore,
  };
};
