<template>
  <div class="flex py-4 justify-center">
    <El-Button @click="$emit('more')">
      <font-awesome-icon :icon="['fas', 'reply']" class="mr-1" />
      <span v-text="'更多'"></span>
    </El-Button>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '更多',
    },
    isEnding: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['more'],
  setup(props, { emit }) {
    return {};
  },
};
</script>
